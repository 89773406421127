<template>
  <v-main>
    <v-container fluid style="position: relative">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card>
            <v-container fluid>
              <v-row v-if="errors">
                <v-col cols="12" sm="12" md="12" v-if="Array.isArray(errors)">
                  <v-alert
                    dense
                    v-for="(v, k) in errors"
                    :key="k"
                    outlined
                    type="error"
                  >{{ k }} {{ v }}</v-alert>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-else>
                  <v-alert
                    dense
                    outlined
                    type="error"
                  >{{ errors }}</v-alert>
                </v-col>
              </v-row>
              <v-card-title v-if="!company_id">Add Company</v-card-title>
              <v-card-title v-if="company_id">Edit Company</v-card-title>
              <v-card-text style="position: relative">
                <v-form id="form" ref="form">
                  <v-row>
                    <v-col cols="12" sm="8" md="8">
                      <v-row>
                        <v-col cols="12" sm="3">
                          <v-text-field
                            outlined
                            label="Company Pre-fix"
                            name="company_prefix"
                            v-model="company_prefix"
                            autocomplete="off"
                            :rules="companyRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="9">
                          <v-text-field
                            outlined
                            label="Company Name"
                            name="company_name"
                            v-model="company_name"
                            autocomplete="off"
                            :rules="companynameRules"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="3">
                          <v-select
                            v-model="adminsalutation"
                            :items="salutation"
                            item-value="value"
                            item-text="name"
                            label="Admin Salutation"
                            autocomplete="off"
                            name="salutation"
                            :rules="adminsalutationRules"
                            outlined
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="9">
                          <v-text-field
                            outlined
                            v-model="company_admin_name"
                            label="Admin Name"
                            autocomplete="off"
                            name="name"
                            :rules="adminnameRules"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            outlined
                            label="Admin Email"
                            autocomplete="off"
                            name="email"
                            v-model="company_admin_email"
                            :rules="adminemailRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            outlined
                            label="Admin Phone Number"
                            autocomplete="off"
                            name="phone_no"
                            type="number"
                            v-model="company_admin_phone_no"
                            :rules="adminphoneRules"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            outlined
                            label="Available Seats"
                            autocomplete="off"
                            name="available_seats"
                            type="number"
                            v-model="available_seats"
                            :rules="availableseatRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-select
                            v-if="company_id!=null"
                            v-on:change="changeModule"
                            v-model="accounttype"
                            :items="items"
                            item-value="value"
                            item-text="name"
                            label="Account Type"
                            autocomplete="off"
                            name="account_type"
                            :rules="accounttypeRules"
                            readonly
                            disabled
                            outlined
                            required
                          ></v-select>
                          <v-select
                            v-else
                            v-on:change="changeModule"
                            v-model="accounttype"
                            :items="items"
                            item-value="value"
                            item-text="name"
                            label="Account Type"
                            autocomplete="off"
                            name="account_type"
                            :rules="accounttypeRules"
                            outlined
                            required
                          ></v-select>

                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-select
                            v-model.lazy="lang_id"
                            :items="languages"
                            item-value="lang_id"
                            item-text="lang_name"
                            label="Company Language"
                            autocomplete="off"
                            name="lang_id"
                            :rules="languagecodeRules"
                            outlined
                            required
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-select
                            v-if="company_id!==null"
                            v-on:change="getProductID"
                            v-model="payment_account"
                            :items="paymentAccounts"
                            item-value="payment_account_id"
                            item-text="payment_account_name"
                            label="Payment Account"
                            autocomplete="off"
                            name="payment_account"
                            :rules="paymentaccountRules"
                            readonly
                            disabled
                            outlined
                            required
                          ></v-select>
                          <v-select
                            v-else
                            v-model="payment_account"
                            :items="paymentAccounts"
                            item-value="payment_account_id"
                            item-text="payment_account_name"
                            label="Payment Account"
                            autocomplete="off"
                            name="payment_account"
                            :rules="paymentaccountRules"
                            @change="getProductID()"
                            outlined
                            required
                          ></v-select>

                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-textarea
                            outlined
                            name="address"
                            :rules="companyaddressRules"
                            autocomplete="off"
                            label="Comapny Address"
                            v-model="address"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="personal_documents"
                            :items="personaldocuments"
                            item-value="perdoc_id"
                            item-text="perdoc_name"
                            autocomplete="off"
                            name="personaldocument"
                            outlined
                            label="Personal Documents"
                            :rules="personalDocumentRules"
                            chips
                            small-chips
                            multiple
                            required
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="selectedCourse"
                            :items="courses"
                            item-value="course_id"
                            item-text="course_name"
                            autocomplete="off"
                            name="courses"
                            outlined
                            label="Course"
                            :rules="courseRules"
                            chips
                            small-chips
                            multiple
                            required
                            v-on:change="addSelectedCourses(selectedCourse)"
                          ></v-select>
                        </v-col>
                      </v-row>


                      <v-row v-if="courseData.length>0">
                        <v-col cols="12" sm="12">
                          <h2 class="pb-4">Selected Courses</h2>

                          <v-expansion-panels multiple>
                            <v-expansion-panel v-for="(coursesdata, index) in courseData" :key="index">
                              <v-expansion-panel-header>{{ coursesdata.course_name }}</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row>
                                    <v-col
                                    v-if="accounttype =='on-demand'"
                                    class="grow">
                                      <v-select
                                        v-model='coursesdata.stripe_product_id'
                                        ref="products"
                                        :items="productsid"
                                        item-value="id"
                                        item-text="name"
                                        label="Course Products"
                                        autocomplete="off"
                                        :rules="productRules"
                                        v-on:change="addProduct(index, `${coursesdata.stripe_product_id}`)"
                                      ></v-select>
                                    </v-col>

                                    <v-col
                                    v-if="accounttype =='on-demand'"
                                    class="grow">
                                      <v-select
                                        v-model='coursesdata.price_id'
                                        :items="(productsid !== undefined && productsid !== null && coursesdata.stripe_product_id!==null ? (productsid[productsid.findIndex(o=> o.id === coursesdata.stripe_product_id )] !== undefined ? productsid[productsid.findIndex(o=> o.id === coursesdata.stripe_product_id )].plans : [] ): [])"
                                        item-value="plan_id"
                                        item-text="plan_name"
                                        label="Course Product Pricing Plans"
                                        autocomplete="off"
                                        :rules="planRules"
                                        v-on:input="addPlan(index, `${coursesdata.price_id}`)"
                                      ></v-select>
                                    </v-col>
                                </v-row>
                                  
                                  <v-row
                                   v-if="accounttype =='on-demand'">
                                    <v-checkbox
                                      v-model="coursesdata.voucher_product_id" 
                                      :label="`Has Voucher Products?`"
                                    ></v-checkbox>
                                  </v-row>

                                  <v-row v-if="coursesdata.voucher_product_id">
                                    <v-col
                                    v-if="accounttype =='on-demand'"
                                    class="grow">
                                      <v-select
                                        v-model='coursesdata.voucher_product_id'
                                        ref="voucherProducts"
                                        :items="vouchersid"
                                        item-value="id"
                                        item-text="name"
                                        label="Voucher Products"
                                        autocomplete="off"
                                        v-on:change="addVoucherProduct(index, `${coursesdata.voucher_product_id}`)"
                                      ></v-select>
                                    </v-col>

                                    <v-col
                                    v-if="accounttype =='on-demand'"
                                    class="grow">
                                      <v-select
                                        v-model='coursesdata.voucher_price_id'
                                        ref="voucherPrice"
                                        :items="(vouchersid !== undefined && vouchersid !== null && coursesdata.voucher_product_id!==null ? (vouchersid[vouchersid.findIndex(o=> o.id === coursesdata.voucher_product_id )] !== undefined ? vouchersid[vouchersid.findIndex(o=> o.id === coursesdata.voucher_product_id )].plans : [] ): [])"
                                        item-value="plan_id"
                                        item-text="plan_name"
                                        label="Voucher Product Pricing Plan"
                                        autocomplete="off"
                                        :rules="voucherRules(index)"
                                        v-on:input="addVoucherPrice(index, `${coursesdata.voucher_price_id}`)"
                                      ></v-select>
                                    </v-col>

                                    <v-col
                                    v-if="accounttype =='on-demand'"
                                    class="grow">
                                      <v-select
                                        v-model='coursesdata.coupon_price_id'
                                        ref="discountAmount"
                                        :items="(vouchersid !== undefined && vouchersid !== null && coursesdata.voucher_product_id!==null ? (vouchersid[vouchersid.findIndex(o=> o.id === coursesdata.voucher_product_id )] !== undefined ? productsid[productsid.findIndex(o=> o.id === coursesdata.stripe_product_id )].plans : [] ): [])"
                                        item-value="plan_id"
                                        item-text="plan_name"
                                        label="Applied Voucher Product Pricing Plan"
                                        autocomplete="off"
                                        :rules="couponRules(index)"
                                        v-on:input="addDiscountPrice(index, `${coursesdata.coupon_price_id}`)"
                                      ></v-select>
                                      
                                    </v-col>

                                </v-row>

                                <v-row>
                                  <v-col cols="12" sm="12">
                                       <v-checkbox
                                          v-model="coursesdata.has_sort" 
                                          :label="`Has Sort?`"
                                          @click="hasSortChanged(coursesdata)"
                                          hide-details
                                        ></v-checkbox>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12" sm="12">
                                      <v-chip-group
                                        active-class="primary--text"
                                        column
                                      >
                                        <v-chip
                                          v-for="modulesSelected in coursesdata.company_modules"
                                          :key="modulesSelected.module_id"
                                        >
                                        <v-avatar
                                          right
                                          class="primary mr-2 white--text"
                                          v-if="coursesdata.has_sort"
                                        >
                                          {{ modulesSelected.sort_sequence }}
                                        </v-avatar>
                                          {{ modulesSelected.title }}
                                        </v-chip>
                                      </v-chip-group>

                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12" sm="12">
                                    <v-text-field
                                      outlined
                                      autocomplete="off"
                                      label="Search Modules"
                                      :ref="'search'+coursesdata.course_id"
                                      @keyup="onClickSearch('search'+coursesdata.course_id,coursesdata.course_id)"
                                    ></v-text-field>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="4"
                                        v-for="datas in searchData[coursesdata.course_id]"
                                        :key="datas.module_id"
                                      >
                                        <v-card class="ma-2 mt-n1" height="100%" max-width="374">
                                          <v-img
                                            height="250"
                                            :src="storage_url + datas.thumbnail "
                                          ></v-img>
                                          <v-card-title style="position: relative">
                                            {{ datas.title }}
                                            <v-btn
                                              absolute
                                              dark
                                              fab
                                              top
                                              right
                                              @click="removeModule(coursesdata.course_id,datas)"
                                              color="pink lighten-1"
                                              v-if="checkModule(coursesdata.course_id ,datas.module_id)"
                                            >
                                              <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <v-btn
                                              absolute
                                              dark
                                              fab
                                              top
                                              right
                                              @click="addModule(coursesdata.course_id,datas)"
                                              color="green lighten-1"
                                              v-else
                                            >
                                              <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                          </v-card-title>
                                          <v-card-text>
                                            <div>{{ datas.description | readMore(255, '...') | stripeHTML }}... </div>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>

                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
  
                      <v-row>
                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="has_tutor_products"
                              :items="tutorProductList"
                              item-value="value"
                              item-text="option"
                              label="Has Tutor Product"
                              autocomplete="off"
                              name="has_tutor_product"
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>

                         <v-row v-if="has_tutor_products=='true'">
                            <v-col cols="12" sm="12">
                              <v-select
                                v-model="selectedTProduct"
                                :items="tutorproducts"
                                item-value="tutor_product_id"
                                item-text="tutor_product_name"
                                autocomplete="off"
                                name="tutor_products"
                                outlined
                                label="Tutor Product"
                                chips
                                small-chips
                                multiple
                                required
                                v-on:change="addSelectedTProducts(`${tutorproducts}`,selectedTProduct)"
                              ></v-select>
                            </v-col>
                          </v-row>

                      <v-row v-if="tutorProductData.length>0 && has_tutor_products=='true' && accounttype =='on-demand'">
                        <v-col cols="12" sm="12">
                          <h2 class="pb-4">Selected Tutor Products</h2>

                          <v-expansion-panels multiple>
                            <v-expansion-panel v-for="(tutorData, index2) in tutorProductData" :key="index2">
                              <v-expansion-panel-header>{{ tutorData.tutor_product_name }}</v-expansion-panel-header>
                              
                              <v-expansion-panel-content>
                                  
                         <v-row v-if="has_tutor_products=='true'">
                              <v-col
                              v-if="accounttype =='on-demand'"
                              class="grow">
                                <v-select
                                  v-model='tutorData.stripe_product_id'
                                  ref="tutor_products"
                                  :items="tutorproductsid"
                                  item-value="id"
                                  item-text="name"
                                  label="Tutor Products"
                                  autocomplete="off"
                                  :rules="tutorproductRules"
                                  v-on:change="addTutorProduct(`${tutorData.stripe_product_id}`)"
                                ></v-select>
                              </v-col>

                              <v-col
                              v-if="accounttype =='on-demand'"
                              class="grow">
                                <v-select
                                  v-model='tutorData.price_id'
                                  :items="(tutorproductsid !== undefined && tutorproductsid !== null && tutorData.stripe_product_id!==null ? (tutorproductsid[tutorproductsid.findIndex(o=> o.id === tutorData.stripe_product_id )] !== undefined ? tutorproductsid[tutorproductsid.findIndex(o=> o.id === tutorData.stripe_product_id )].plans : [] ): [])"
                                  item-value="plan_id"
                                  item-text="plan_name"
                                  label="Tutor Product Pricing Plans"
                                  autocomplete="off"
                                  :rules="tutorplanRules"
                                  v-on:input="addTutorPlan(`${tutorData.price_id}`)"
                                ></v-select>
                              </v-col>
                          </v-row>
                                  
                          <v-row
                            v-if="accounttype =='on-demand' && has_tutor_products=='true'">
                            <v-checkbox
                              v-model="tutorData.voucher_product_id" 
                              :label="`Has Tutor Voucher Products?`"
                              @click="removeVoucherProducts(tutorData.voucher_product_id,tutorData)"
                            ></v-checkbox>
                          </v-row>

                          <v-row v-if="tutorData.voucher_product_id">
                            <v-col
                            v-if="accounttype =='on-demand'"
                            class="grow">
                              <v-select
                                v-model='tutorData.voucher_product_id'
                                ref="tutorVoucherProducts"
                                :items="tutorvouchersid"
                                item-value="id"
                                item-text="name"
                                label="Tutor Voucher Products"
                                autocomplete="off"
                                v-on:change="addTutorVoucherProduct(`${tutorData.voucher_product_id}`)"
                              ></v-select>
                            </v-col>

                            <v-col
                            v-if="accounttype =='on-demand'"
                            class="grow">
                              <v-select
                                v-model='tutorData.voucher_price_id'
                                ref="tutorvoucherPrice"
                                :items="(tutorvouchersid !== undefined && tutorvouchersid !== null && tutorData.voucher_product_id!==null ? (tutorvouchersid[tutorvouchersid.findIndex(o=> o.id === tutorData.voucher_product_id )] !== undefined ? tutorvouchersid[tutorvouchersid.findIndex(o=> o.id === tutorData.voucher_product_id )].plans : [] ): [])"
                                item-value="plan_id"
                                item-text="plan_name"
                                label="Tutor Voucher Product Pricing Plan"
                                autocomplete="off"
                                :rules="tutorvoucherRules(index2)"
                                v-on:input="addTutorVoucherPrice(`${tutorData.voucher_price_id}`)"
                              ></v-select>
                            </v-col>

                            <v-col
                            v-if="accounttype =='on-demand'"
                            class="grow">
                              <v-select
                                v-model='tutorData.coupon_price_id'
                                ref="tutordiscountAmount"
                                :items="(tutorvouchersid !== undefined && tutorvouchersid !== null && tutorData.voucher_product_id!==null ? (tutorvouchersid[tutorvouchersid.findIndex(o=> o.id === tutorData.voucher_product_id )] !== undefined ? tutorproductsid[tutorproductsid.findIndex(o=> o.id === tutorData.stripe_product_id )].plans : [] ): [])"
                                item-value="plan_id"
                                item-text="plan_name"
                                label="Applied Tutor Voucher Product Pricing Plan"
                                autocomplete="off"
                                :rules="tutorcouponRules(index2)"
                                v-on:input="addTutorDiscountPrice(`${tutorData.coupon_price_id}`)"
                              ></v-select>
                            </v-col>

                        </v-row>

                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-col>


                    <v-col cols="12" sm="4" md="4">
                      <v-row>
                        <v-col v-if="url" cols="12" sm="12">
                          <v-img
                            :src="url"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            max-width="500"
                            max-height="300"
                          ></v-img>
                        </v-col>

                        <v-col cols="12" sm="12">
                          <v-file-input
                            id="logo"
                            name="logo"
                            ref="imageUploader"
                            outlined
                            chips
                            label="Company Logo"
                            @change="onFileSelected"
                          ></v-file-input>
                        </v-col>

                        <v-col cols="12" sm="12">
                          <v-text-field
                            label="Company Color"
                            name="primary_color"
                            autocomplete="off"
                            :rules="companycolorRules"
                            v-model="primary_color"
                            hide-details
                            outlined
                            class="ma-0 pa-0"
                            required
                          >
                            <template v-slot:append>
                              <v-menu
                                v-model="menu"
                                top
                                nudge-bottom="105"
                                nudge-left="16"
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on }">
                                  <div :style="swatchStyle" v-on="on" />
                                </template>
                                <v-card>
                                  <v-card-text class="pa-0">
                                    <v-color-picker v-model="primary_color" flat />
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="company_domain"
                            :items="domainList"
                            item-value="value"
                            item-text="name"
                            autocomplete="off"
                            name="company_domain"
                            outlined
                            label="Company domain"
                            chips
                            small-chips
                          ></v-select>
                        </v-col>


                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="has_equip_db"
                            :items="equipmentstatusList"
                            item-value="value"
                            item-text="name"
                            label="Equipment status"
                            autocomplete="off"
                            name="has_equip_db"
                            :rules="equipmentRules"
                            outlined
                            required
                          ></v-select>
                        </v-col>
                        </v-row>

                        <v-row class="mt-n5">
                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="has_task_center"
                              :items="taskstatusList"
                              item-value="value"
                              item-text="name"
                              label="Task center status"
                              autocomplete="off"
                              name="has_task_center"
                              :rules="taskcenterRules"
                              outlined
                              required
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row class="mt-n5">
                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="has_inbox"
                              :items="inboxList"
                              item-value="value"
                              item-text="name"
                              label="Show Inbox"
                              autocomplete="off"
                              name="has_inbox"
                              :rules="inboxRules"
                              outlined
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      
                        <v-row class="mt-n5">
                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="has_external_users"
                              :items="externalUserList"
                              item-value="value"
                              item-text="name"
                              label="Show External Users"
                              autocomplete="off"
                              name="has_external_users"
                              :rules="externalUsersRules"
                              outlined
                              required
                            ></v-select>
                          </v-col>
                        </v-row>

                      <v-row class="mt-n5">
                        <v-col cols="12" sm="12">
                          <v-text-field
                            outlined
                            label="Feedback Emails"
                            autocomplete="off"
                            name="Feedback emails"
                            v-model="course_feedback_emails"
                            :rules="course_feedback_emails_Rules"
                            placeholder="Email, Email ..."
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="mt-n5">
                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="has_stats_emails"
                              :items="has_stats_emails_List"
                              item-value="value"
                              item-text="name"
                              label="Daily stats"
                              autocomplete="off"
                              name="has_stats_emails"
                              :rules="has_stats_emails_Rules"
                              outlined
                              required
                            ></v-select>
                          </v-col>
                        </v-row>

                          <v-row class="mt-n5">
                        <v-col cols="12" sm="12" v-if="has_stats_emails =='true'">
                          <v-text-field
                            outlined
                            label="Stats emails"
                            autocomplete="off"
                            name="stats_emails "
                            v-model="stats_emails"
                            placeholder="Email, Email..."
                            :rules="stats_emails_Rules"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="mt-n5">
                        <v-col cols="12" sm="12">
                          <v-text-field
                            outlined
                            label="Inquiry Email"
                            autocomplete="off"
                            name="inquiry_email"
                            v-model="inquiry_email"
                            :rules="inquiryemailRules"
                            placeholder="example@example.com"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      
                      <v-row class="mt-n5">
                         <v-col cols="12" sm="12">
                          <v-select
                            v-model="login_options"
                            :items="loginOptions"
                            :rules="loginOptionsrequiredRules"                            
                            item-value="login_value"
                            item-text="login_option"
                            label="Login Options"
                            autocomplete="off"
                            multiple
                            outlined
                            required
                          ></v-select> 
                        </v-col>
                      </v-row>

                      <v-row class="mt-n5">
                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="has_quiz"
                              :items="quizList"
                              item-value="value"
                              item-text="name"
                              label="Show Quiz"
                              autocomplete="off"
                              name="has_quiz"
                              :rules="quizRules"
                              outlined
                              required
                            ></v-select>
                          </v-col>
                        </v-row>

                    </v-col>
                  </v-row>
                </v-form>

                <v-btn
                  dark
                  fab
                  fixed
                  bottom
                  right
                  large
                  color="pink"
                  v-if="!company_id"
                  class="mx-2"
                  @click="openDialog"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>

                <v-btn
                  dark
                  fab
                  fixed
                  bottom
                  right
                  large
                  color="pink"
                  v-if="company_id"
                  class="mx-2"
                  @click="update"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Dialogbox
      @closing="closing"
      @submit="submit"
      :title="dialogboxtitle"
      :dialog="dialogstatus"
      :buttonTitle="dialogButtontitle"
      v-if="dialogstatus == true"
    />


  </v-main>
</template>

<script>
import {
  FETCH_INDIVIDUAL_COMPANY,
  FETCH_LANGUAGES,
  FETCH_MODULES,
  FETCH_PRODUCTS_ID,
  FETCH_VOUCHER_PRODUCTS_ID,
  FETCH_TUTOR_PRODUCTS,
  FETCH_TUTOR_PRODUCTS_ID,
  FETCH_TUTOR_VOUCHER_PRODUCTS_ID,
  STRIPE_RESET_STATE,
  FETCH_PERSONAL_DOCUMENTS,
  COMPANIES_RESET_STATE,
  LANGUAGES_RESET_STATE,
  FETCH_COURSES,
  ADD_COMPANY,
  UPDATE_COMPANY,
  MODULES_RESET_STATE
} from "@/store/actions.type";
import { SET_SNACKBAR, SET_LOADING } from "@/store/mutations.type";
import store from "@/store";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  name: "EditCompany",
  async beforeRouteUpdate(to, from, next) {
    Promise.all([
    await store.dispatch(COMPANIES_RESET_STATE),
    await store.dispatch(LANGUAGES_RESET_STATE),
    await store.dispatch(MODULES_RESET_STATE),
    ])
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
    await store.dispatch(COMPANIES_RESET_STATE),
    await store.dispatch(LANGUAGES_RESET_STATE),
    await store.dispatch(MODULES_RESET_STATE),
    await store.dispatch(FETCH_PERSONAL_DOCUMENTS, ""),
    await store.dispatch(FETCH_LANGUAGES,''),
    await store.dispatch(FETCH_COURSES, ""),
    await store.dispatch(FETCH_TUTOR_PRODUCTS, ""),
    await store.commit(SET_LOADING, false)
    ])
    if (to.params.id) {
      await store.dispatch(FETCH_INDIVIDUAL_COMPANY, to.params.id);
      await store.commit(SET_LOADING, false)
    }
    return next();
  },
  async beforeRouteLeave(to, from, next) {
    Promise.all([
    await store.dispatch(COMPANIES_RESET_STATE),
    await store.dispatch(LANGUAGES_RESET_STATE),
    await store.dispatch(MODULES_RESET_STATE),
    ])
    next();
  },
  computed: {
    ...mapGetters(['individualcompany', 'courses','modules','languages','productsid','vouchersid','tutorproducts','tutorproductsid', 'tutorvouchersid' ,'personaldocuments']),
    ...mapState({
      errors: state => state.company.companyerrors
    }),
    swatchStyle() {
      const { primary_color, menu } = this;
      return {
        backgroundColor: primary_color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  },
  components: {
    Dialogbox: () => import("@/components/dialog/dialog")
  },
  created() {
    this.storage_url  = process.env.VUE_APP_STORAGE_URL
    if (this.individualcompany.company_id != undefined) {
      this.company_prefix = this.individualcompany.company_prefix;
      this.company_name = this.individualcompany.company_name;
      this.accounttype = this.individualcompany.account_type;
      this.adminsalutation = this.individualcompany.company_admin.salutation;
      this.company_admin_name = this.individualcompany.company_admin.name;
      this.company_admin_email = this.individualcompany.company_admin.email;
      this.company_admin_phone_no = this.individualcompany.company_admin.phone_no;
      this.company_domain = this.individualcompany.company_domain
      this.address = this.individualcompany.address;
      this.company_id = this.individualcompany.company_id;
      this.payment_account = this.individualcompany.payment_account
      this.lang_id = this.individualcompany.lang_id;
      this.personal_documents = this.individualcompany.personal_documents;
      this.available_seats = this.individualcompany.available_seats;
      let companymeta = JSON.parse(this.individualcompany.company_meta);
      this.primary_color = companymeta.primary_color;

      this.has_equip_db = companymeta.has_equip_db
      this.has_task_center = companymeta.has_task_center
      this.has_inbox =   companymeta.has_inbox
      this.has_external_users =   companymeta.has_external_users
      this.inquiry_email = companymeta.inquiry_email
      this.stats_emails = companymeta.stats_emails
      this.has_stats_emails = companymeta.has_stats_emails
      // this.stats_emails = this.stats_emails
      // this.has_stats_emails = this.has_stats_emails
      this.course_feedback_emails = companymeta.course_feedback_emails
      this.login_options = companymeta.login_options
      this.has_quiz =   companymeta.has_quiz
      this.url = this.storage_url + this.individualcompany.company_logo;
      this.getProductID()
      this.individualcompany.courses.forEach(item => {
        this.selectedCourse.push(item.course_id)
        var mod =[]
        item.company_modules.forEach(item2 => {
           let newmod = {
                module_id: item2.module_id,
                title: item2.title,
                sort_sequence: item2.sort_sequence,
            };
            mod.push(newmod);
        });
        let data = {
            course_id: item.course_id,
            course_name: item.course_name,
            stripe_product_id: item.plan.stripe_product_id,
            price_id: item.plan.price_id,
            voucher_product_id: item.plan.voucher_product_id,
            voucher_price_id: item.plan.voucher_price_id, 
            voucher_amount: item.plan.voucher_amount, 
            coupon_price_id: item.plan.coupon_price_id,
            coupon_amount: item.plan.coupon_amount, 
            has_sort: item.has_sort,
            company_modules: mod,
          };
          this.courseData.push(data)
          this.renderModules(item.course_id)
      });

      this.has_tutor_products = 'false'
      this.individualcompany.tutor_products.forEach(item => {
        this.selectedTProduct.push(item.tutor_product_id)
        let tdata = {
            tutor_product_id: item.tutor_product_id,
            tutor_product_name: item.tutor_product_name,
            stripe_product_id: item.plan.stripe_product_id,
            price_id: item.plan.price_id,
            voucher_product_id: item.plan.voucher_product_id,
            voucher_price_id: item.plan.voucher_price_id, 
            voucher_amount: item.plan.voucher_amount, 
            coupon_price_id: item.plan.coupon_price_id,
            coupon_amount: item.plan.coupon_amount, 
          };
          this.tutorProductData.push(tdata)
          this.has_tutor_products = 'true'
      });
    }
  },
  data: () => ({
    company_prefix: null,
    company_name: null,
    accounttype: null,
    adminsalutation: null,
    has_equip_db: null,
    has_task_center: null,
    has_inbox: null,
    has_external_users: false,
    company_admin_name: null,
    company_admin_email: null,
    company_admin_phone_no: null,
    address: null,
    selectedCourse:[],
    courseData: [],
    selectedTProduct:[],
    tutorProductData: [],
    login_options:[],
    has_tutor_products: false,
    has_stats_emails: null,
    url: null,
    payment_account: null,
    lang_id: null,
    companylogo: null,
    company_id: null,
    storage_url: null,
    available_seats: null,
    personal_documents: [],
    inquiry_email: null,
    stats_emails: null,
    course_feedback_emails: null,
    modulesdata: {},
    searchData: {},
    selectedModulesData: new Array(),
    primary_color: "#1976D2FF",
    menu: false,
    dialogstatus: false,
    has_quiz: null,
    companyRules: [v => !!v || "Company Prefix is required"],
    companynameRules: [v => !!v || "Company Name is required"],
    adminsalutationRules: [v => !!v || "Admin Salutation is required"],
    adminnameRules: [v => !!v || "Admin Name is required"],
    inboxRules: [v => !!v || "Show Inbox is required"],
    externalUsersRules: [v => !!v || "External Users is required"],
    adminemailRules: [v => /.+@.+/.test(v) || "Invalid Admin Email address"],
    inquiryemailRules: [v => /.+@.+/.test(v) || "Inquiry Email is required"],
    adminphoneRules: [v => !!v || "Admin Phone Number is required"],
    availableseatRules: [v => !!v || "Available Seats is required"],
    accounttypeRules: [v => !!v || "Account Type is required"],
    companyaddressRules: [v => !!v || "Company Address is required"],
    companycolorRules: [v => !!v || "Company Color is required"],
    languagecodeRules: [v => !!v || "Company Language is required"],
    paymentaccountRules: [v => !!v || "Payment Account is required"],
    productRules: [v => !!v || "Products are required"],
    planRules: [v => !!v || "Plan is required"],
    tutorproductRules: [v => !!v || "Tutor Product is required"],
    tutorplanRules: [v => !!v || "Tutor Plan is required"],
    equipmentRules: [v => !!v || "Equipment status is required"],
    taskcenterRules: [v => !!v || "Task center status is required"],
    courseRules: [v => !!v || "Course is required"],
    personalDocumentRules: [v => !!v || "Personal documents is required"],
    stats_emails_Rules: [v => !!v || "Stats email are required"],
    course_feedback_emails_Rules: [v => !!v || "Course feedback emails are required"],
    has_stats_emails_Rules: [v => !!v || "Daily stats is required"],
    loginOptionsrequiredRules:[v => !!v || "Login Option is required"],
    quizRules: [v => !!v || "Show Quiz is required"],
    loading: false,
    selection: 1,
    company_domain: 'learn-ndt.com',
    items: [
      { value: "on-demand", name: "On Demand" },
      { value: "full-version", name: "Full Version" }
    ],
    equipmentstatusList: [
      { value: "true", name: "Yes" },
      { value: "false", name: "No" }
    ],
    has_stats_emails_List: [
      { value: "true", name: "Yes" },
      { value: "false", name: "No" }
    ],
    taskstatusList: [
      { value: "true", name: "Yes" },
      { value: "false", name: "No" }
    ],
    inboxList: [
      { value: "true", name: "Yes" },
      { value: "false", name: "No" }
    ],
    externalUserList: [
      { value: "true", name: "Yes" },
      { value: "false", name: "No" }
    ],
    domainList: [
      { value: "learn-ndt.com", name: "learn-ndt.com" },
      { value: "eve-hub.de", name: "eve-hub.de" },
      { value: "coboc.biz", name: "coboc.biz" }
    ],
    paymentAccounts: [
      { payment_account_id: "STAGING", payment_account_name: "STAGING" },
      { payment_account_id: "PRODUCTION", payment_account_name: "PRODUCTION" }
    ],
    salutation: [
      { value: "Mr", name: "Mr" },
      { value: "Ms", name: "Ms" },
      { value: "Mrs", name: "Mrs" }
    ],
    tutorProductList: [
      { value: "false", option: "No" },
      { value: "true", option: "Yes" }
    ],
    loginOptions:[
      { login_value: "lms_login", login_option: "LMS Login" },
      { login_value: "microsoft_login", login_option: "Microsoft Login" }
    ],
    quizList:[
      { value: "true", name: "Yes" },
      { value: "false", name: "No" }
    ],
    isLoading: false,
    model: null,
    dialogboxtitle: 'Payment Account and Account type will be locked, Are you sure you want to proceed?',
    dialogButtontitle: 'Yes',
  }),

  methods: {
    openDialog(){
      if (this.$refs.form.validate()) {
        this.$store.commit(SET_LOADING, false)
        this.dialogstatus = true
      }
    },
    closing(){
      this.dialogstatus = false
      this.$store.commit(SET_LOADING, false)
    },
    voucherRules(index){
      if(!this.courseData[index].voucher_price_id){
        return ["Required"]
      }
      else{
        return [true]
      }
    },
    couponRules(index){
      if(!this.courseData[index].voucher_price_id){
        return ["Required"]
      }
      else{
        return [true]
      }
    },
    tutorvoucherRules(index){
      if(!this.tutorProductData[index].voucher_price_id){
        return ["Required"]
      }
      else{
        return [true]
      }
    },
    tutorcouponRules(index){
      if(!this.tutorProductData[index].voucher_price_id){
        return ["Required"]
      }
      else{
        return [true]
      }
    },
    addSelectedCourses(courseselected){
        this.courseData = this.courseData
        .filter(course =>{
          if(courseselected.includes(course.course_id)){
            return course
          }
          else{
            delete this.searchData[''+course.course_id+'']
            delete this.modulesdata[''+course.course_id+'']
          }
        }
        )
        let data = courseselected
        .filter(ids => !this.courseData.some(course=>course.course_id == ids))
        .map(ids =>{
          this.renderModules(ids)
          return {
            course_id: ids,
            course_name: this.courses.find(coursedata=>  coursedata.course_id == ids).course_name,
            price_id: null,
            stripe_product_id: null,
            voucher_product_id: null,
            voucher_price_id: null,
            voucher_amount: null,
            coupon_price_id: null,
            has_sort: false,
            company_modules: [],
          };
        })
        this.courseData = [...this.courseData, ...data]
    },
    addSelectedTProducts(name,selectedTProduct){
        this.tutorProductData = this.tutorProductData
        .filter(tutor_product =>{
          if(selectedTProduct.includes(tutor_product.tutor_product_id)){
            return tutor_product
          }
          else{
            delete this.searchData[''+tutor_product.tutor_product_id+'']
          }
        }
        )
        let tdata = selectedTProduct
        .filter(ids => !this.tutorProductData.some(tutor_product=>tutor_product.tutor_product_id == ids))
        .map(ids =>{
          return {
            tutor_product_id: ids,
            tutor_product_name: this.tutorproducts.find(tutorProductData=>  tutorProductData.tutor_product_id == ids).tutor_product_name,
            price_id: null,
            stripe_product_id: null,
            voucher_product_id: null,
            voucher_price_id: null,
            voucher_amount: null,
            coupon_price_id: null,
          };
        })
        this.tutorProductData = [...this.tutorProductData, ...tdata]
    },
    async renderModules(ids){
      await this.$store.dispatch(FETCH_MODULES,'?course_ids='+ids).then(resp=>{
        if(resp.success){
          let modulesArray = this.courseData[this.courseData.findIndex(course => course.course_id == ids)].company_modules.sort()
          let modulesData = resp.data.sort((a,b) => {
              return modulesArray.indexOf(b.module_id) - modulesArray.indexOf(a.module_id);
            })
          this.$set(this.searchData, ""+ids+"" ,modulesData)
          this.modulesdata[''+ids+'']=modulesData
        }
      })
    },
    checkModule(course_id,module_id) {
      return this.courseData[this.courseData.findIndex(course => course.course_id == course_id)].company_modules.some(x => x.module_id === module_id)
    },
    async getProductID(){
        this.courseData = this.courseData.map(item => {
          return {
            course_id: item.course_id,
            course_name: item.course_name,
            price_id: '',
            stripe_product_id: '',
            voucher_product_id: '',
            voucher_price_id: '',
            coupon_price_id: '',
            has_sort: false,
            company_modules: item.company_modules,
          };
        });

     await this.$store.dispatch(STRIPE_RESET_STATE)
     await this.$store.dispatch(FETCH_PRODUCTS_ID,'list-products?type=course&payment_account='+this.payment_account)
     await this.$store.dispatch(FETCH_VOUCHER_PRODUCTS_ID,'list-products?type=course_voucher&payment_account='+this.payment_account)
     await this.$store.dispatch(FETCH_TUTOR_PRODUCTS_ID,'list-products?type=tutor_product&payment_account='+this.payment_account)
     return await this.$store.dispatch(FETCH_TUTOR_VOUCHER_PRODUCTS_ID,'list-products?type=tutor_product_voucher&payment_account='+this.payment_account).then(()=>{
      this.$store.commit(SET_LOADING, false)
      return true
     })
    },
    changeModule() {
      if (this.accounttype == "full-version") {
        this.courseData = this.courseData.map(item => {
          return {
            course_id: item.course_id,
            course_name: item.course_name,
            price_id: '',
            stripe_product_id: '',
            voucher_product_id: '',
            voucher_price_id: '',
            coupon_price_id: '',
            has_sort: false,
            company_modules: item.company_modules,
          };
        });
      }
    },
    addPlan(index, id) {
      this.courseData[index].price_id = id;
    },
    addProduct(index, productid) {
      this.courseData[index].stripe_product_id = productid
    },
    addVoucherProduct(index, id) {
      this.courseData[index].voucher_product_id = id
      this.courseData[index].voucher_price_id = null
      this.courseData[index].coupon_price_id = null
    },
    addVoucherPrice(index, id) {
      this.courseData[index].voucher_price_id = id
    },
    addDiscountPrice(index, id) {
      this.courseData[index].coupon_price_id = id
    },
    searchModule(searchQuery,courseid) {
      let data = [];
      for (var i = 0; i < this.modulesdata[''+courseid+''].length; i++) {
        if (
          this.modulesdata[''+courseid+''][i].title
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        ) {
          data.push(this.modulesdata[''+courseid+''][i]);
        }
      }
      return data;
    },
    addTutorProduct(stripe_product_id) {
      this.tutorProductData.stripe_product_id = stripe_product_id
    },
    addTutorPlan(id) {
      this.tutorProductData.price_id = id;
    },
    addTutorVoucherProduct(id) {
      this.tutorProductData.voucher_product_id = id
      this.tutorProductData.voucher_price_id = null
      this.tutorProductData.coupon_price_id = null
    },
    addTutorVoucherPrice(id) {
      this.tutorProductData.voucher_price_id = id
    },
    addTutorDiscountPrice(id) {
      this.tutorProductData.coupon_price_id = id
    },
    onFileSelected() {
      if (document.getElementById("logo").value != "") {
        this.companylogo = document.getElementById("logo").files[0];
        this.url = URL.createObjectURL(this.companylogo);
      }
    },
    onClickSearch(searchvalue,courseid) {
      if (this.$refs[searchvalue][0].internalValue.length > 0) {
        this.$set(this.searchData, ""+courseid+"" ,this.searchModule(this.$refs[searchvalue][0].internalValue,courseid))
      }
      else{
        this.$set(this.searchData, ""+courseid+"" ,this.modulesdata[''+courseid+''])
      }
    },
    addModule(courseid , data) {
      this.courseData[this.courseData.findIndex(course => course.course_id == courseid)].company_modules.push({
              module_id: data.module_id,
              title: data.title,
              sort_sequence: (this.courseData[this.courseData.findIndex(course => course.course_id == courseid)].has_sort) ? ((this.courseData[this.courseData.findIndex(course => course.course_id == courseid)].company_modules).length)+1 : null,
          })
      this.sortModuleList(courseid);

    },
    removeModule(courseid, data) {
      let index = this.courseData.findIndex(course => course.course_id == courseid)
      this.courseData[index].company_modules = this.courseData[index].company_modules.filter(ids => ids.module_id != data.module_id)
      this.courseData[index].has_sort??this.sortModuleList(courseid);
      this.sortModuleList(courseid);
    },
    sortModuleList(courseid){
      let index = this.courseData.findIndex(course => course.course_id == courseid)
      var mod =[]
      var sort_sequence = 1;
      this.courseData[index].company_modules.forEach(item2 => {
          let newmod = {
              module_id: item2.module_id,
              title: item2.title,
              sort_sequence: sort_sequence++,
          };
          mod.push(newmod);
      });
      this.courseData[index].company_modules = mod;
    },
    removeVoucherProducts(tutor_product_id,tdata){
      if(!tutor_product_id){
        tdata.voucher_product_id = null;
        tdata.voucher_price_id = null;
        tdata.voucher_amount = null;
        tdata.coupon_price_id = null;
        tdata.coupon_amount = null;
      }
    },
    submit: function() {
      if (this.$refs.form.validate()) {
        var formData = new FormData(document.getElementById("form"));
        formData.set("account_type", this.accounttype);
        formData.set("salutation", this.adminsalutation);
        formData.set("payment_account", this.payment_account);
        if(this.personal_documents.length>0){
          this.personal_documents.forEach(function(item) {
            formData.append("personal_documents[]", item)
          });
        }
        if (this.companylogo !== null) {
          formData.set("company_logo", this.companylogo);
        }
        formData.set("lang_id", this.lang_id);
        formData.set("company_domain", this.company_domain);

        let datacourse = this.courseData.map(item => {
          return {
            course_id: item.course_id,
            price_id: item.price_id,
            stripe_product_id: item.stripe_product_id,
            voucher_product_id: item.voucher_product_id,
            voucher_price_id: item.voucher_price_id,
            coupon_price_id: item.coupon_price_id,
            has_sort: item.has_sort,
            company_modules: item.company_modules,
          };
        });

        formData.set("courses", JSON.stringify(datacourse));
        
        let datatutor = this.tutorProductData.map(item => {
          return {
            tutor_product_id: item.tutor_product_id,
            price_id: item.price_id,
            stripe_product_id: item.stripe_product_id,
            voucher_product_id: item.voucher_product_id,
            voucher_price_id: item.voucher_price_id,
            voucher_amount: item.voucher_amount,
            coupon_price_id: item.coupon_price_id,
            coupon_amount: item.coupon_amount,
          };
        });

        formData.set("tutor_products", JSON.stringify(datatutor));

        formData.set(
          "company_meta",
          JSON.stringify({ primary_color: this.primary_color, has_equip_db: this.has_equip_db, has_task_center: this.has_task_center, has_inbox: this.has_inbox, has_external_users: this.has_external_users, course_feedback_emails: this.course_feedback_emails ,inquiry_email: this.inquiry_email, has_stats_emails:this.has_stats_emails, stats_emails: this.stats_emails, login_options: this.login_options, has_quiz: this.has_quiz, has_tutor_products: this.has_tutor_products })
        );
        this.$store.dispatch(ADD_COMPANY, formData).then(resp => {
          if(resp.success){
            this.dialogstatus = false
            store.commit(SET_LOADING, false)
            store.commit(SET_SNACKBAR, {status: true, message: 'Company created successfully'})
            this.$router.push({
              name: "company"
            });
          }
          else{
            store.commit(SET_LOADING, false)
          }

        });
      }
    },
    update: function() {
      if (this.$refs.form.validate()) {
        var formData = new FormData(document.getElementById("form"));
        formData.set("account_type", this.accounttype);
        formData.set("salutation", this.adminsalutation);
        formData.set("company_id", this.company_id);
        formData.set("lang_id", this.lang_id);
        formData.set("payment_account", this.payment_account);
        formData.set("user_id", this.individualcompany.company_admin.user_id);
        formData.set("company_domain", this.company_domain);
        if(this.personal_documents.length>0){
          this.personal_documents.forEach(function(item) {
            formData.append("personal_documents[]", item)
          });
        }

        if (this.companylogo !== null) {
          formData.set("company_logo", this.companylogo);
        }

        let datacourse = this.courseData.map(item => {
          return {
            course_id: item.course_id,
            price_id: item.price_id,
            stripe_product_id: item.stripe_product_id,
            voucher_product_id: item.voucher_product_id,
            voucher_price_id: item.voucher_price_id,
            coupon_price_id: item.coupon_price_id,
            has_sort: item.has_sort,
            company_modules: item.company_modules,
          };
        });

        formData.set("courses", JSON.stringify(datacourse));

         let datatutor = this.tutorProductData.map(item => {

          return {
            tutor_product_id: item.tutor_product_id,
            price_id: item.price_id,
            stripe_product_id: item.stripe_product_id,
            voucher_product_id: item.voucher_product_id,
            voucher_price_id: item.voucher_price_id,
            voucher_amount: item.voucher_amount,
            coupon_price_id: item.coupon_price_id,
            coupon_amount: item.coupon_amount,
          };
        });
        formData.set("tutor_products", JSON.stringify(datatutor));

        if(this.has_tutor_products=='false'){
          formData.set("tutor_products", []);
        }

        formData.set(
          "company_meta",
          JSON.stringify({ primary_color: this.primary_color, has_equip_db: this.has_equip_db, has_task_center: this.has_task_center, has_inbox: this.has_inbox, has_external_users: this.has_external_users,course_feedback_emails:this.course_feedback_emails,  inquiry_email: this.inquiry_email, has_stats_emails:this.has_stats_emails, stats_emails: this.stats_emails, login_options: this.login_options, has_quiz: this.has_quiz, has_tutor_products: this.has_tutor_products })
        );
        formData.set("_method", "put");
        this.$store.dispatch(UPDATE_COMPANY, formData).then(resp => {
          if(resp.success){
            this.$router.push({
              name: "company"
            });
            store.commit(SET_SNACKBAR, {status: true, message: 'Company updated successfully'})

          }
          else{
            store.commit(SET_LOADING, false)
          }
        });
      }
    },
    hasSortChanged(coursedata){
      var mod =[]
      var sort_sequence = 1;
      coursedata.company_modules.forEach(item2 => {
          let newmod = {
              module_id: item2.module_id,
              title: item2.title,
              sort_sequence: coursedata.has_sort ? sort_sequence++ : false,
          };
          mod.push(newmod);
      });
          this.courseData[this.courseData.findIndex(course => course.course_id == coursedata.course_id)].company_modules = mod;
    }
  }
};
</script>
